import React, { useEffect } from "react"
import { navigate } from "gatsby"

import { authSignOut } from "../utils/auth"

const SignOut = () => {
  useEffect(() => {
    authSignOut(() => navigate(`/`))
  }, [])

  return <div />
}

export default SignOut
